import OwnerModel             from '@models/map/OwnerModel';
import ProjectStatusModel     from '@models/map/ProjectStatusModel';
import doc                    from '@widesk-core/decorators/doc';
import model                  from '@widesk-core/decorators/model';
import MapServiceModel        from '@/models/MapServiceModel';
import TimestampAble          from '@widesk-core/models/traits/TimestampAble';
import Blamable               from '@/models/traits/Blamable';
import { computed }           from 'mobx';
import reverseResolvable      from '@widesk-core/decorators/reverseResolvable';
import StonecodeFileFileModel from '../file/StonecodeFileFileModel';
import { RenderNameProps }    from '@widesk-ui/mixins/ApiModelRenderName';
import FileHelper             from '@/helpers/FileHelper';

@model.urnResource('project')
@doc.path('/projects/{?id}')
export default class ProjectModel extends Blamable(TimestampAble(MapServiceModel)) {
	private declare _tsFilters: {
		'createdAt': string;
		'maps': id;
		'owner': id;
		'owner.accountUrn': Urn;
		'placeTypes': id;
		'places': id;
		'projectStatus': id;
		'projectStatusHistories': id;
		'updatedAt': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'title': string;
		'updatedAt': string;
	};

	@doc.model(OwnerModel) declare owner: OwnerModel;
	@doc.model(ProjectStatusModel) declare projectStatus: ProjectStatusModel;
	@doc.string declare title: string;

	@computed
	public get imageFile() {
		return reverseResolvable(this, StonecodeFileFileModel, {
			propertyName: 'imageFile',
			modelIdProperty: 'urn',
			getModelIdFromResolvable: async file => {
				await file.typeSource.source.whenIsLoaded();
				return file.typeSource.source.entityUrn;
			},
			filters: ({ urns }) => ({
				'typeSource.type.reference': 'project_image' as const,
				'typeSource.source.entityUrn': urns,
				'fileStatus.reference': 'available' as const,
			}),
			onFetchSuccess: async m => {
				if (m.imageFile.id) {
					const src = await FileHelper.fetchSrcForFile(m.imageFile);
					m.set('imageSrc', src);
				}
			},
		});
	}

	public get imageSrc(): string {
		return this.get('imageSrc', '');
	}

	public renderName(props?: RenderNameProps<this> & RenderNameImageProps) {
		return super.renderName(props);
	}
}