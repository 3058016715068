import doc             from '@widesk-core/decorators/doc';
import model           from '@widesk-core/decorators/model';
import MapServiceModel from '@/models/MapServiceModel';
import TimestampAble   from '@widesk-core/models/traits/TimestampAble';
import Blamable        from '@/models/traits/Blamable';

export enum ProjectStatusId {
	enabled = '1',
	disabled = '2',
}

@model.cacheDuration()
@model.urnResource('project_status')
@doc.path('/project_statuses/{?id}')
export default class ProjectStatusModel extends Blamable(TimestampAble(MapServiceModel)) {
	private declare _tsFilters: {
		'projectStatusHistories': id;
		'projects': id;
		'reference': Map_ProjectStatusReference;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
	};

	@doc.string declare label: string;
	@doc.string declare reference: Map_ProjectStatusReference;

	public get color() {
		switch (this.reference) {
			case 'enabled':
				return 'green';
			case 'disabled':
				return 'orange';
			default:
				return 'default';
		}
	}
}