import React                  from 'react';
import { WithRenderName }     from '@widesk-ui/mixins/ApiModelRenderName';
import { WithLocalizedLabel } from '@widesk-core/mixins/ApiModelLocalizedLabel';
import ApiModel               from '@widesk-core/models/ApiModel';

type ConfirmOptions = {
	actionLabel?: string,
	hideModelName?: boolean,
	content?: React.ReactNode;
};

type M = ApiModel & WithRenderName & WithLocalizedLabel;

const confirmModelMessage = (modelArr: M | M[], options: ConfirmOptions) => {
	const models = Array.isArray(modelArr) ? modelArr : [modelArr];

	const actionLabel = options.actionLabel || 'supprimer';

	const isMany = models.length > 1;

	const renderModelLabel = (model: M) => {
		return <>
			{options.hideModelName ? '' : `${model.localize()} - `}
			{model.renderName()}
		</>;
	};

	return (
		<span>
			{`Voulez-vous ${actionLabel} ${isMany ? `les éléments` : `l'élément`} : `}

			<ul>
				{models.map(m => {
					return (
						<li key={m.id}>
							{renderModelLabel(m)}
						</li>
					);
				})}
			</ul>
		</span>
	);
};

export const confirmModelAction = (m: M | M[], options: ConfirmOptions) => {
	const actionLabel = options.actionLabel || 'supprimer';
	const models = Array.isArray(m) ? m : [m];

	return {
		title: confirmModelMessage(models, { actionLabel }),
		content: options.content,
	};
};

export default confirmModelMessage;
