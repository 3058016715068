import './accountWall.scss';

import useAccount       from "@/hooks/useAccount";
import UserAccountModel from '@/models/services/account/UserAccountModel';
import appStore         from "@/stores/appStore";
import Button           from "@widesk-ui/components/Button";
import Title            from "@widesk-ui/components/Title";
import View             from "@widesk-ui/components/View";
import useApiCollection from "@widesk-core/hooks/useApiCollection";
import { observer }     from "mobx-react";
import { useEffect }    from 'react';

const AccountWall = observer(() => {
	const userAccountCollection = useApiCollection(UserAccountModel);

	useEffect(() => {
		(async () => {
			if (appStore.user.urn) {
				await userAccountCollection.listBy(appStore.user.urn, 'user');
			}
		})();
	}, [appStore.user.urn]);

	const { selectAccount } = useAccount();

	if (userAccountCollection.length === 1) {
		selectAccount(userAccountCollection.first()!.account.id);
	}

	return (
		<View gap="lg" padding="lg">
			<View gap="lg" center>
				<Title level={2} bold>Bonjour&nbsp;{appStore.user.modelLabel}</Title>
				<Title level={5}>Sélectionnez un compte</Title>
				<View className="account-wall__container" wrap>
					{userAccountCollection.map(userAccount => (
						<View row space="between" center marginT="lg" key={userAccount.account.id} bg="white" padding="lg" className="account-wall__card" gap="sm">
							<View>
								<Title marginB="xs" level={5} bold>{userAccount.account.renderName()}</Title>
								{userAccount.account.accountStatus.renderName()}
							</View>
							<View marginL="lg">
								<Button onClick={() => selectAccount(userAccount.account.id)} type="primary">Sélectionner</Button>
							</View>
						</View>
					))}
				</View>
			</View>
		</View>
	);
});

export default AccountWall;
