import WideskNavigation                          from '@widesk-ui/components/Navigation';
import navigation                                from '@/navigation';
import { navigationSettings }                    from '@/navigation';
import useTheme                                  from '@widesk-ui/hooks/useTheme';
import View                                      from '@widesk-ui/components/View';
import Link                                      from '@widesk-ui/components/Link';
import ProjectSelector                           from './ProjectSelector';
import { BuildOutlined }                         from "@ant-design/icons";
import { EnvironmentOutlined }                   from "@ant-design/icons";
import { LayoutOutlined }                        from "@ant-design/icons";
import { PushpinOutlined }                       from "@ant-design/icons";
import { CaretDownOutlined }                     from "@ant-design/icons";
import { useLocation }                           from 'react-router-dom';
import { useParams }                             from 'react-router-dom';
import useAccount                                from '@/hooks/useAccount';

export default function LayoutSider() {
	const theme = useTheme();
	const { account } = useAccount();

	const { id, projectId: pid } = useParams();
	const location = useLocation();

	let projectId = pid;
	if (id && !pid && location.pathname.startsWith('/projects/')) {
		projectId = id;
	}

	return (
		<>
			<View className="sider-top" flex style={{ overflow: 'auto' }}>
				<View center paddingT={theme.marginMD} paddingB={theme.marginXS * 1.8}>
					<Link to="/">
						<img className="app-logo" style={{ width: '195px', height: '30px', maxWidth: '80%', display: 'block', margin: 'auto' }} src="/logo-white.svg" alt="" />
					</Link>
				</View>
				{account && (
					<View className="sider-anim" flex style={{ overflow: 'auto' }}>
						<ProjectSelector placement="bottomLeft" projectId={projectId} icon={<CaretDownOutlined />} type="primary">
							Choisir un projet
						</ProjectSelector>
						<WideskNavigation items={navigation} />
						{projectId && (
							<WideskNavigation items={[
								{ label: 'Aperçu général', icon: <LayoutOutlined />, link: `/projects/${projectId}` },
								{ label: 'Cartes', icon: <EnvironmentOutlined />, link: `/projects/${projectId}/maps/split` },
								{ label: 'Places', icon: <PushpinOutlined />, link: `/projects/${projectId}/places/split` },
								{ label: 'Types de places', icon: <BuildOutlined />, link: `/projects/${projectId}/place_types/split` },
							]} />
						)}
					</View>
				)}
			</View>

			{account && (
				<div className="sider-bottom sider-anim">
					{/* Paramètres */}
					<WideskNavigation
						items={navigationSettings}
					/>
				</div>
			)}
		</>
	);
}
