import { computed }        from 'mobx';
import { jwtDecode }       from 'jwt-decode';
import AccountServiceModel from '@/models/AccountServiceModel';

export default class LoginCheckModel extends AccountServiceModel {
	static get path(): string {
		return '/login_check';
	}

	@computed get token(): string {
		return this.get('token', '');
	}

	@computed get refreshToken(): string {
		return this.get('refresh_token', '');
	}

	@computed get tokenData(): any {
		return jwtDecode(this.token) || {};
	}
}
