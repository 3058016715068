import doc              from '@widesk-core/decorators/doc';
import model            from '@widesk-core/decorators/model';
import FileServiceModel from '@/models/FileServiceModel';
import TimestampAble    from '@widesk-core/models/traits/TimestampAble';
import Blamable         from '@/models/traits/Blamable';

@model.urnResource('stonecode_file_source')
@doc.path('/stonecode_file_sources/{?id}')
export default class StonecodeFileSourceModel extends Blamable(TimestampAble(FileServiceModel)) {
	private declare _tsFilters: {
		'entityUrn': Urn;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'updatedAt': string;
	};

	@doc.string declare entityUrn: Urn;
}
