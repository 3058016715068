import actionDeleteModel   from '@widesk-ui/tools/actionModel/actionDeleteModel';
import actionEditModel     from '@widesk-ui/tools/actionModel/actionEditModel';
import actionAddModel      from '@widesk-ui/tools/actionModel/actionAddModel';
import actionEditManyModel from '@widesk-ui/tools/actionModel/actionEditManyModel';
import actionUnlinkModel   from '@widesk-ui/tools/actionModel/actionUnlinkModel';

export default {
	add: actionAddModel,
	unlink: actionUnlinkModel,
	delete: actionDeleteModel,
	edit: actionEditModel,
	editMany: actionEditManyModel,
};
