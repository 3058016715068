import logout        from "@/tools/logout";
import useDidMount   from "@widesk-core/hooks/useDidMount";
import { navigate }  from "@widesk-ui/hooks/useNavigate";

function ImpersonateLogged() {
	useDidMount(() => {
		if (window.location.pathname.startsWith('/impersonate')) {
			logout();
		} else {
			navigate('/');
		}
	});
	return null;
}

export default ImpersonateLogged;
