import ApiModel               from '@widesk-core/models/ApiModel';
import React                  from 'react';
import { Action }             from '@widesk-ui/components/ButtonActions';
import { Modal }              from '@widesk-ui/components/modal/Modal';
import { ModalProps }         from '@widesk-ui/components/modal/Modal';
import { PlusOutlined }       from '@ant-design/icons';
import { WithLocalizedLabel } from '@widesk-core/mixins/ApiModelLocalizedLabel';
import { uniqueId }           from 'lodash';

type AddOptions<M extends typeof ApiModel> = {
	hideModelName?: boolean;
	modalProps?: Partial<ModalProps> | ((model: InstanceType<M>) => Partial<ModalProps>),
	actionProps?: Partial<Action>,
};

const actionAddModel = <MC extends typeof ApiModel & WithLocalizedLabel>(
	modelClass: MC,
	form: React.ReactNode | ((model: InstanceType<MC>) => React.ReactNode),
	options?: AddOptions<MC>,
): Action => {
	const label = `Ajouter` + (options?.hideModelName ? '' : ` - ${modelClass.localize()}`);

	return {
		label,
		icon: <PlusOutlined />,
		onClick: async () => {
			const model = new (modelClass as any)() as InstanceType<MC>;

			const children = typeof form === 'function' ? form(model) : form;
			const modalOptions = typeof options?.modalProps === 'function'
				? options?.modalProps(model)
				: options?.modalProps;

			return Modal.open({ 
				title: label, 
				children, 
				okButtonProps: { 
					htmlType: 'submit',
					form: uniqueId(),
				},
				...modalOptions,
			});
		},
		...options?.actionProps,
	};
};

export default actionAddModel;
