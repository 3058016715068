export default function getDefaultJsonData(): OL.jsonData {
	return {
		configurations: {
			initialZoom: 16,
			maxZoom: 22,
			displayStreets: true,
			rotation: 0,
			tileLayerName: 'osm',
			geolocation: false,
		},
		zones: { type: 'FeatureCollection', features: [] },
	};
}