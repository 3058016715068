import View                  from '@widesk-ui/components/View';
import useTheme              from '@widesk-ui/hooks/useTheme';
import LayoutHeaderLogout    from '@widesk-ui/components/LayoutHeaderLogout';
import useThemeMode          from '@widesk-ui/hooks/useThemeMode';
import appStore              from '@/stores/appStore';
import ButtonActions         from '@widesk-ui/components/ButtonActions';
import useAccount            from '@/hooks/useAccount';
import AccountSelector       from './AccountWall/AccountSelector';
import authStore             from '@widesk-core/stores/authStore';
import Tag                   from '@widesk-ui/components/Tag';
import { UserOutlined }      from '@ant-design/icons';
import { navigate } from '@widesk-ui/hooks/useNavigate';

export default function LayoutHeader() {
	const { account } = useAccount();

	return (
		<View row spread>
			<View className="app__account-selector">
				{account && (
					<AccountSelector />
				)}
			</View>

			<View row>
				<UserHeaderSettings />

				<LayoutHeaderLogout />
			</View>
		</View>
	);
}

const UserHeaderSettings = () => {
	const { mode, setMode } = useThemeMode();
	const { colorPrimary } = useTheme();
	const isDark = mode === 'dark';

	return (
		<View bg={colorPrimary} color="white" center minWidth={150}>
			<ButtonActions
				actions={[
					{
						label: 'Informations du compte',
						onClick: () => navigate('/my-account'),
					},
					{
						label: 'Afficher le thème ' + (isDark ? 'clair' : 'sombre'),
						onClick: () => setMode(isDark ? 'light' : 'dark'),
					},
				]}
				icon={
					authStore.tokenData['impersonate.urn'] ? (
						<Tag icon={<UserOutlined />} color="blue">Vous êtes connecté en tant que {appStore.user.renderName()}</Tag> 
					) : (
						appStore.user.renderName()
					)
				}
				type="text"
				block
				style={{ color: 'white', height: '64px', borderRadius: 0 }}
			/>
		</View>
	);
};
