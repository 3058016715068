import { ModelClass }         from '@mathquis/modelx/lib/types/collection';
import { ICollectionOptions } from '@mathquis/modelx/lib/types/collection';
import { ConnectorResults }   from '@mathquis/modelx/';
import { computed }           from 'mobx';
import { action }             from 'mobx';
import { observable }         from 'mobx';
import { makeObservable }     from 'mobx';
import { override }           from 'mobx';
import ApiCollection          from '@widesk-core/models/ApiCollection';
import ApiModel               from '@widesk-core/models/ApiModel';
import _get                   from 'lodash/get';

export default class PagedCollection<T extends ApiModel> extends ApiCollection<T> {
	@observable public total = 0;

	@observable protected _page = 1;

	@observable private _itemsPerPage = 10;

	public constructor(model: ModelClass<T>, models?: T[], options?: ICollectionOptions) {
		super(model, models, options);

		makeObservable(this);
	}

	public clear() {
		this._page = 1;

		return super.clear();
	}

	@action setItemsPerPage(itemsPerPage: number) {
		this._itemsPerPage = itemsPerPage;

		return this;
	}

	@action setPage(page: number) {
		this._page = page;

		return this;
	}

	@action setTotal(value: number) {
		this.total = value;

		return this;
	}

	@computed get itemsPerPage(): number {
		return this._itemsPerPage;
	}

	@computed get page() {
		return this._page;
	}

	@computed get hasPreviousPage() {
		return this._page > 1;
	}

	@computed get hasNextPage() {
		return this.total > this._page * this._itemsPerPage;
	}

	@computed get hasSinglePage() {
		return this.total <= this._itemsPerPage;
	}

	@override onListSuccess(results: ConnectorResults, options: any) {
		const path = _get(this, 'model.connector.resultTotalPath', '') as string;
		this.total = _get(results.res.data, path, 0);
		super.onListSuccess(results, options);
	}

	protected prepareListOptions(options: any): object {
		return super.prepareListOptions({
			...options,
			params: {
				itemsPerPage: this._itemsPerPage,
				page: this._page,
				...options.params,
			},
		});
	}
}
