import ApiCollection        from '@widesk-core/models/ApiCollection';
import ApiModel             from '@widesk-core/models/ApiModel';
import React                from 'react';
import useDidMount          from '@widesk-core/hooks/useDidMount';
import useWillUnmount       from '@widesk-core/hooks/useWillUnmount';
import { useAddCollection } from '@widesk-core/hooks/useAllCollections';

export default function useCollection<
	COLL extends ApiCollection<ApiModel>
>(createCollection: () => COLL, fetch = false) {
	const collection = React.useMemo(() => createCollection(), []);

	useDidMount(() => fetch && collection.list());
	useWillUnmount(() => {
		collection.abort();
	});

	useAddCollection(collection);

	return collection;
}
