import appStore                  from "@/stores/appStore";
import cacheSystem               from "@widesk-core/models/apiCacheSystem";
import authStore                 from "@widesk-core/stores/authStore";
import listFilterManagerStore    from '@widesk-ui/stores/ListFilterManagerStore';

export const onLogout = async () => {
	cacheSystem.clear();
	listFilterManagerStore.clear();
	appStore.clear();
};

export default async () => {
	onLogout();
	authStore.clear();
};