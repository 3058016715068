import doc                 from '@widesk-core/decorators/doc';
import model               from '@widesk-core/decorators/model';
import AccountServiceModel from '@/models/AccountServiceModel';
import TimestampAble       from '@widesk-core/models/traits/TimestampAble';
import Blamable            from '@/models/traits/Blamable';

@model.cacheDuration()
@model.urnResource('user_account_type')
@doc.path('/user_account_types/{?id}')
export default class UserAccountTypeModel extends Blamable(TimestampAble(AccountServiceModel)) {
	private declare _tsFilters: {
		'userAccounts': id;
	};

	private declare _tsSorts: {
		'id': string;
	};

	@doc.string declare label: string;
	@doc.string declare reference: Account_UserAccountTypeReference;
	// TODO DOC - Ajouter la propriété "right"
}