import ProjectModel        from '@models/map/ProjectModel';
import doc                 from '@widesk-core/decorators/doc';
import model               from '@widesk-core/decorators/model';
import MapServiceModel     from '@/models/MapServiceModel';
import TimestampAble       from '@widesk-core/models/traits/TimestampAble';
import Blamable            from '@/models/traits/Blamable';
import WithProjectPagePath from '@/models/traits/WithProjectPagePath';

@model.cacheDuration()
@model.urnResource('place_type')
@doc.path('/place_types/{?id}')
export default class PlaceTypeModel extends Blamable(TimestampAble(WithProjectPagePath(MapServiceModel))) {
	private declare _tsFilters: {
		'createdAt': string;
		'placeTypeFields': id;
		'places': id;
		'project': id;
		'updatedAt': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'label': string;
		'updatedAt': string;
	};	

	@doc.string declare label: string;
	@doc.model(ProjectModel) declare project: ProjectModel;
}
