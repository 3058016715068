import ApiModel         from '@widesk-core/models/ApiModel';
import PagedCollection  from '@widesk-core/models/PagedCollection';
import findAsync        from '@widesk-core/tools/findAsync';
import { reaction }     from 'mobx';
import { when }         from 'mobx';
import { getIdFromUrn } from '@widesk-core/tools/identifier';

export default function reverseResolvable<
	SOURCE_MODEL extends ApiModel,
	RESOLVABLE_CLASS extends typeof ApiModel,
	RESOLVABLE_FILTERS extends ModelFilters<InstanceType<RESOLVABLE_CLASS>>,
>(
	model: SOURCE_MODEL,
	resolvableClass: RESOLVABLE_CLASS,
	options: {
		propertyName: string,
		sortName?: ModelSortName<InstanceType<RESOLVABLE_CLASS>>;
		sortWay?: SortWay;
		filters: (params: { ids: id[]; urns: Urn[]; }) => RESOLVABLE_FILTERS | Promise<RESOLVABLE_FILTERS>;
		getModelIdFromResolvable: (coll: InstanceType<RESOLVABLE_CLASS>) => id | Promise<id>;
		modelIdProperty?: keyof InstanceType<RESOLVABLE_CLASS>; // nom de la propriété qui sera utilisée pour mapper les résultats ("id" par défaut")
		splitRequestById?: boolean; // Permet de grouper les requêtes par model
		onFetchSuccess?: (model: SOURCE_MODEL) => void | Promise<void>; // S'éxécute pour chaque resolvable juste avant de passer en "isLoaded"
	},
) {
	const key = `reverse_resolvable_${options.propertyName}` as any;
	const collectionKey = `collection_${key}` as any;

	const splitRequestById = options.splitRequestById;

	const collectionSource: any = model.collection || model;

	// ---------------------------------------------------------------- //
	// ----------------- INITIALISATION DU RESOLVABLE ----------------- //
	// ---------------------------------------------------------------- //
	if (!collectionSource[collectionKey]) {
		collectionSource[collectionKey] = new PagedCollection(resolvableClass);

		const resolvableCollection = collectionSource[collectionKey] as PagedCollection<ApiModel>;

		const fetch = () => {
			if (!resolvableCollection.isLoading && !resolvableCollection.isLoaded && collectionSource['isLoaded']) {
				const ids = (model.collection)?.map(m => m.id) || [model.id];
				const urns = (model.collection)?.map(m => m.get('@urn')) || [model.get('@urn')];

				(async () => {
					const requests = [];

					if (splitRequestById) {
						requests.push(...await Promise.all(urns.map(async urn => {
							const filters = await options.filters({ ids: [getIdFromUrn(urn)], urns: [urn] });

							return { filters };
						})));
					} else {
						const filters = await options.filters({ ids, urns });

						requests.push({ filters });
					}

					resolvableCollection.setIsLoading(true);

					const colls = await Promise.all(requests.map(async request => {
						if (!Object.keys(request.filters).length) {
							throw `You must specify at least one filter to use "reverseResolvables". => ${options.propertyName}`;
						}

						const itemsPerPage = splitRequestById ? 1 : ids.length;

						const coll = new PagedCollection(resolvableClass);

						return coll
							.setItemsPerPage(itemsPerPage)
							.setFilters(request.filters) // TODO Utiliser "setRequiredFilters"
							.setSort(options.sortName as any || 'id', options.sortWay)
							.list({
								maxResults: itemsPerPage,
								params: { partial: true },
							});
					}));

					resolvableCollection.set(colls.map(coll => coll.models).flat());

					resolvableCollection.setIsLoading(false);
					resolvableCollection.setIsLoaded(true);
				})();
			}
		};

		const modelIdReactionDisposer = reaction(
			() => model.id,
			async (newId) => {
				if (newId) {
					fetch();
				}
			},
		);

		const collectionSourceLoadingReactionDisposer = reaction(
			() => collectionSource.isLoading,
			async (newIsLoading) => {
				if (!newIsLoading) {
					resolvableCollection.clear();
					fetch();
				}
			},
		);

		const allModels = (model.collection ? [...model.collection.models] : [model]) as SOURCE_MODEL[];

		allModels.forEach(m => {
			(m as any)[key] = new resolvableClass();

			const mResolvable = (m as any)[key] as InstanceType<RESOLVABLE_CLASS>;

			// Pour le "whenIsLoaded"
			mResolvable['propName'] = options.propertyName;
			mResolvable['isReverseResolvable'] = true;
			mResolvable['calledBy'] = m;
		});

		const collectionSourceLoadedReactionDisposer = reaction(
			() => resolvableCollection.isLoaded,
			async (newIsLoaded) => {
				if (newIsLoaded) {
					await Promise.all(
						allModels.map(async m => {
							const modelId = options.modelIdProperty ? (m as any)[options.modelIdProperty] : m.id;
							const mResolvable = (m as any)[options.propertyName] as InstanceType<RESOLVABLE_CLASS>;

							const newResolvable = await findAsync(
								resolvableCollection.models,
								async r => await options.getModelIdFromResolvable(r as any) === modelId,
							);

							if (newResolvable) {
								mResolvable.set(newResolvable.attributes);
							}

							if (options.onFetchSuccess) {
								await options.onFetchSuccess(m);
							}

							mResolvable.setIsLoaded(true);

							// Quand le model parent est clear
							when(() => !m.id, async () => {
								resolvableCollection.clear();
								mResolvable.clear();
							});
						}),
					);
				}
			},
		);

		// Quand la collection source est actualisée
		when(() => collectionSource.isLoading, () => {
			modelIdReactionDisposer();
			collectionSourceLoadingReactionDisposer();
			collectionSourceLoadedReactionDisposer();

			// On supprime la collection de resolvable précédemment créée de façon à réinitialiser les reverseResolvables
			delete collectionSource[collectionKey];
		});

		fetch();
	}
	// ---------------------------------------------------------------- //
	// --------------- FIN INITIALISATION DU RESOLVABLE --------------- //
	// ---------------------------------------------------------------- //

	return (model as any)[key] as InstanceType<RESOLVABLE_CLASS>;
}