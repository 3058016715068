import { ModelClass }  from '@mathquis/modelx/lib/types/collection';
import ApiModel        from '@widesk-core/models/ApiModel';
import React           from 'react';
import PagedCollection from '@widesk-core/models/PagedCollection';
import ApiCollection   from '@widesk-core/models/ApiCollection';

const usedCollections: Record<string, ApiCollection<ApiModel> | PagedCollection<ApiModel>> = {};

export const getCollectionsForClass = (modelClass: ModelClass<ApiModel>) => Object.values(usedCollections).filter(c => c.model.name === modelClass.name);

export const reloadCollectionsForClass = (modelClass: ModelClass<ApiModel>) => Promise.all(getCollectionsForClass(modelClass).map(c => c.list()));

export function useAddCollection(collection: ApiCollection<ApiModel> | PagedCollection<ApiModel>) {
	React.useEffect(() => {
		usedCollections[collection.uniqueId] = collection;

		return () => {
			delete usedCollections[collection.uniqueId];
		};
	}, []);
}
