import React          from 'react';
import AntdSelect     from 'antd/lib/select';
import Spin           from 'antd/lib/spin';
import useWillUnmount from '@widesk-core/hooks/useWillUnmount';

export type SelectOption = {
	disabled?: boolean;
	label: React.ReactNode;
} & ({
	value?: undefined;
	options: SelectOption[];
} | {
	value: unknown;
	options?: undefined;
})

export type SelectProps = FieldProps & {
	options: SelectOption[];
	onOpen?: () => void;
	onUnmount?: () => void;
	onPopupScroll?: React.UIEventHandler<HTMLDivElement>;
	loading?: boolean;
	loadingBottom?: boolean;
	onSearch?: (search: string) => void;
	onFocus?: () => void;
	onBlur?: () => void;
	onClear?: () => void;
	searchValue?: string;
	maxTagCount?: number | 'responsive';
	multiple?: boolean;
	allowClear?: boolean;
	status?: 'error' | 'warning';
}

export const Select = React.forwardRef((props: SelectProps, ref) => {
	const { loadingBottom, onOpen, options } = props;

	useWillUnmount(() => props.onUnmount && props.onUnmount());

	return (
		<AntdSelect
			ref={ref as any}
			id={props.id ? `select-${props.id}` : undefined}
			size={props.size}
			autoFocus={props.autoFocus}
			onChange={props.onChange}
			placeholder={props.placeholder}
			disabled={props.disabled}
			value={props.value}
			onClear={props.onClear}
			allowClear={props.allowClear}
			filterOption={false}
			showSearch={!!props.onSearch}
			onSearch={props.onSearch}
			searchValue={props.searchValue}
			onFocus={props.onFocus}
			onBlur={props.onBlur}
			loading={props.loading}
			status={props.status}
			onPopupScroll={props.onPopupScroll}
			maxTagCount={props.maxTagCount}
			mode={props.multiple ? 'multiple' : undefined}
			popupMatchSelectWidth={false}
			style={props.style}
			onDropdownVisibleChange={open => {
				if (onOpen && open) {
					onOpen();
				}
			}}
			options={loadingBottom ? options.concat({
				label: undefined,
				value: <><Spin size="small" />&nbsp;&nbsp;Chargement...</>,
			}) : options}
		/>
	);
});

export default Select;
