import doc                          from '@widesk-core/decorators/doc';
import model                        from '@widesk-core/decorators/model';
import FileServiceModel             from '@/models/FileServiceModel';
import TimestampAble                from '@widesk-core/models/traits/TimestampAble';
import Blamable                     from '@/models/traits/Blamable';
import { downloadUrl }              from '@/helpers/DownloadHelper';
import { getBlob }                  from '@/helpers/DownloadHelper';
import StonecodeFileTypeSourceModel from '@models/file/StonecodeFileTypeSourceModel';
import StonecodeFileMimeTypeModel   from '@models/file/StonecodeFileMimeTypeModel';
import FileHelper                   from '@/helpers/FileHelper';

@model.urnResource('stonecode_file_file')
@doc.path('/stonecode_file_files/{?id}')
export default class StonecodeFileFileModel extends Blamable(TimestampAble(FileServiceModel)) {
	private declare _tsFilters: {
		'fileStatus': id;
		'fileStatus.reference': File_FileStatusReference;
		'typeSource': id;
		'typeSource.source': id;
		'typeSource.source.entityUrn': Urn;
		'typeSource.type': id;
		'typeSource.type.reference': File_StonecodeFileTypeReference;
		'typeSource.type.service': id;
		'typeSource.type.service.serviceUrn': Urn;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'updatedAt': string;
	};

	@doc.date declare date: Dayjs;
	@doc.string declare description: string;
	@doc.string declare file: string;
	@doc.string declare label: string;
	@doc.string declare name: string;
	@doc.number declare size: number;

	@doc.model(StonecodeFileTypeSourceModel) declare typeSource: StonecodeFileTypeSourceModel;
	@doc.model(StonecodeFileMimeTypeModel) declare mimeType: StonecodeFileMimeTypeModel;

	public async fetchBlob() {
		return getBlob(this.downloadPath);
	}

	public async fetchBase64() {
		return FileHelper.fetchSrcForFile(this, { type: 'b64' });
	}

	public async download(name?: string) {
		await downloadUrl(this.downloadPath, name || this.name);
	}

	public get downloadPath() {
		return this.id ? `${this.connector['options'].baseURL}${this.path}/_download` : '';
	}
}