import { Modal }              from '@widesk-ui/components/modal/Modal';
import { ModalProps }         from '@widesk-ui/components/modal/Modal';
import { Action }             from '@widesk-ui/components/ButtonActions';
import { EditOutlined }       from '@ant-design/icons';
import React                  from 'react';
import { WithLocalizedLabel } from '@widesk-core/mixins/ApiModelLocalizedLabel';
import ApiModel               from '@widesk-core/models/ApiModel';
import { uniqueId }           from 'lodash';

type EditOptions = {
	hideModelName?: boolean;
	modalProps?: Partial<ModalProps> | ((model: InstanceType<any>) => Partial<ModalProps>),
	actionProps?: Partial<Action>;
};

type M = ApiModel & WithLocalizedLabel;

const actionEditModel = (
	model: M,
	form: React.ReactNode,
	options?: EditOptions,
): Action => {
	const label = `Modifier` + (options?.hideModelName ? '' : ` - ${model.localize()}`);

	return {
		label,
		icon: <EditOutlined />,
		onClick: () => Modal.open({ 
			title: label, 
			children: form,
			okButtonProps: { 
				htmlType: 'submit',
				form: uniqueId(),
			},
			...options?.modalProps,
		}),
		...options?.actionProps,
	};
};

export default actionEditModel;
