import { SelectCollectionProps } from '@widesk-ui/components/form/SelectCollection';
import React                     from 'react';
import ApiModel                  from '@widesk-core/models/ApiModel';
import { ModelClass }            from '@mathquis/modelx/lib/types/collection';

const createSelect = <T extends ModelClass<ApiModel>, P = object>(
	content: React.FC<Partial<SelectCollectionProps<T>> & P>,
) => {
	return content;
};

export default createSelect;
