import './accountWall.scss';
import { CaretDownOutlined }                     from "@ant-design/icons";
import Button                                    from "@widesk-ui/components/Button";
import Popover                                   from "@widesk-ui/components/Popover";
import Title                                     from "@widesk-ui/components/Title";
import View                                      from "@widesk-ui/components/View";
import { observer }                              from "mobx-react";
import { navigate }                              from '@widesk-ui/hooks/useNavigate';
import { useEffect }                             from 'react';
import { useState }                              from 'react';
import useAccount                                from '@/hooks/useAccount';
import useApiCollection                          from '@widesk-core/hooks/useApiCollection';
import AccountModel                              from '@/models/services/account/AccountModel';
import useDidMount                               from '@widesk-core/hooks/useDidMount';
import UserAccountModel                          from '@/models/services/account/UserAccountModel';
import appStore                                  from '@/stores/appStore';

const AccountSelector = observer(() => {
	const accountCollection = useApiCollection(AccountModel);
	const userAccountCollection = useApiCollection(UserAccountModel);

	useDidMount(async () => {
		await userAccountCollection.listBy(appStore.user.urn, 'user');
		accountCollection.setSort('name');
		accountCollection.setRequiredFilter('id', userAccountCollection.map(userAccount => userAccount.getId('account')));
		accountCollection.list();
	});
	
	const { account, accountUrn, selectAccount } = useAccount();

	const [open, setOpen] = useState(false);
	useEffect(() => {
		setOpen(false);
	}, [accountUrn]);

	const handleOpenChange = (newOpen: boolean) => {
		setOpen(newOpen);
	};

	if (accountCollection.length < 2) {
		return null;
	}

	return (
		<Popover 
			trigger="click"
			open={open}
			onOpenChange={handleOpenChange}
			placement="right"
			content={
				<View className="account-wall__container" wrap>
					{accountCollection.models.map((account, i) => (
						<View row space="between" center marginT={i > 0 ? "xs" : undefined} key={account.id} bg="white" padding="xs" className="account-wall__card" gap="sm">
							<View>
								<Title marginB="xxs" level={5} bold>{account.renderName()}</Title>
								{account.accountStatus.renderName()}
							</View>
							<View marginL="lg">
								<Button 
									onClick={() => {
										navigate('/');
										selectAccount(account.id);
										setOpen(false);
									}} 
									type="primary"
								>
									Sélectionner
								</Button>
							</View>
						</View>
					))}
				</View>
			}
		>
			<Button
				title="Changer de compte"
				type="link"
			>
				{account?.renderName()} <CaretDownOutlined />
			</Button>
		</Popover>
	);
});

export default AccountSelector;
