import SelectCollection from '@widesk-ui/components/form/SelectCollection';
import createSelect     from '@widesk-ui/tools/createSelect';
import ProjectModel     from '@models/map/ProjectModel';
import View             from '@widesk-ui/components/View';
import Title            from '@widesk-ui/components/Title';

const Select = createSelect<typeof ProjectModel>(props => (
	<SelectCollection
		modelClass={ProjectModel}		
		renderOption={project => (
			<View row gap="sm">
				<Title level={5}>{project.renderName({ imageSize: 'small' })}</Title>
			</View>
		)}
		sortName="updatedAt"
		sortWay="desc"

		{...props}
	/>
));

export default Select;